<template>
  <footer class="footer">
    <div class="container-xl footer-view">
      <div>{{ marksAmount }} marks</div>
      <div class="system-data">{{ browser.name }} {{ browser.version }}</div>
      <div>{{ wordsAmount }} words, {{ charactersAmount }} characters</div>
    </div>
  </footer>
</template>

<script>
import Bowser from 'bowser'

export default {
  name: 'Footer',
  data() {
    const bowser = Bowser.getParser(window.navigator.userAgent)

    return {
      wordsAmount: 0,
      charactersAmount: 0,
      browser: {
        name: bowser.getBrowserName(),
        version: bowser.getBrowserVersion()
      }
    }
  },
  computed: {
    redactedText() {
      return this.$store.state.query.redactedText
    },
    marksAmount() {
      return this.$store.state.g.corrections.filter(
        (crn) => crn.type === 'correction'
      ).length
    },
    editorElement() {
      return this.$store.state.g.editorElement
    }
  },
  watch: {
    redactedText() {
      this.countWords()
      this.countCharacters()
    }
  },
  created() {
    this.countWords()
    this.countCharacters()
  },
  methods: {
    countWords() {
      if (!this.editorElement || this.editorElement.innerText === '') {
        this.wordsAmount = 0
      } else {
        this.wordsAmount = this.editorElement.innerText
          .trim()
          .split(/[\s]+/).length
      }
    },
    countCharacters() {
      if (!this.editorElement || this.editorElement.innerText === '') {
        this.charactersAmount = 0
      } else {
        this.charactersAmount = this.editorElement.innerText
          .trim()
          .replaceAll('\n', '').length
      }
    }
  }
}
</script>

<style scoped>
.footer {
  position: fixed;
  width: 100%;
  bottom: 0px;
  background: #f0f4f9;
}
.footer-view {
  height: 40px;
  padding: 8px 32px;
  display: flex;
  justify-content: space-between;
}
.system-data {
  color: gray;
}
</style>
