<template>
  <div class="container-xl">
    <div class="d-flex flex-column justify-content-between vh-100">
      <Header />
      <router-view />
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from '../components/Header'
import Footer from '../components/Footer'

export default {
  name: 'default',
  components: {
    Header,
    Footer
  }
}
</script>

<style></style>

<style scoped></style>
