<template>
  <header>
    <div class="logo"></div>
    <div class="buttons">
      <template v-if="isEditorMode">
        <button
          v-if="isClearButton"
          class="btn btn-secondary me-2"
          @click="clearAll"
        >
          Clear corrections
        </button>
        <button
          class="btn btn-primary"
          :disabled="!isClearButton"
          @click="goToGrades"
        >
          Go to grades
        </button>
      </template>
      <template v-if="isGradesMode">
        <button class="btn btn-secondary me-2" @click="backToText">
          Back to corrections
        </button>
        <button
          v-if="isClearButton && isGradesMode"
          class="btn btn-primary"
          @click="goToShare"
        >
          Publish text
        </button>
      </template>
      <template v-if="isShareMode">
        <button class="btn btn-secondary" @click="backToGrades">
          Back to grades
        </button>
      </template>
      <button v-if="isPublished" class="btn btn-primary" @click="handleNewText">
        Add new text
      </button>
    </div>
  </header>
</template>

<script>
import { onlyDeleteSelection } from '@/utils/CorrectionUtils'
import { asyncBootstrapModal } from '@/components/modals/ConfirmModal.vue'
import { mapMutations, mapState } from 'vuex'

const headerModes = {
  editor: '/',
  grades: '/grades',
  share: '/share'
}

export default {
  name: 'Header',
  computed: {
    ...mapState(['isPublished']),
    currentRoutePath() {
      return this.$router.currentRoute.value.href
    },
    isEditorMode() {
      return this.currentRoutePath === headerModes.editor
    },
    isGradesMode() {
      return this.currentRoutePath === headerModes.grades
    },
    isShareMode() {
      return this.currentRoutePath === headerModes.share && !this.isPublished
    },
    isClearButton() {
      return (
        this.$store.state.query?.originalText
          ?.replaceAll('<br>', '')
          ?.trim() !== ''
      )
    }
  },
  methods: {
    ...mapMutations(['clearState']),
    reset() {
      this.clearState()
      this.backToGrades()
    },
    backToGrades() {
      this.$router.push({ path: '/grades' })
    },
    async clearAll() {
      if (this.$store.state.g.corrections.length) {
        try {
          await asyncBootstrapModal('confirmClear')
        } catch (e) {
          console.error(e)
          return
        }

        this.$store.state.g.corrections.forEach(crn => {
          onlyDeleteSelection(this.$store.state.g.editorElement, crn.id)
        })
        this.$store.state.g.corrections = []
      } else {
        try {
          await asyncBootstrapModal('confirmClear_2')
        } catch (e) {
          console.error(e)
          return
        }

        this.$store.state.g.editorElement.innerHTML = ''
      }
    },
    backToText() {
      this.$router.push({ path: '/' })
    },
    async goToGrades() {
      // rules1
      try {
        const res = await this.$store.state.forceCheck1(
          {
            ...this.$store.state.query
          },
          this.$store.state.forceCheck1Rules
        )
        if (!res) return
      } catch (e) {
        console.error(e)
        return
      }

      this.$store.state.query.finalText = '???'
        .replace(/<span[^>]+>/g, '')
        .replaceAll('</span>', '')

      this.$store.state.query.remarks = this.$store.state.g.corrections
      this.$router.push({ path: '/grades' })
    },
    async goToShare() {
      // rules2
      try {
        const res = await this.$store.state.forceCheck1(
          {
            ...this.$store.state.query
          },
          this.$store.state.forceCheck2Rules
        )
        if (!res) return
      } catch (e) {
        console.error(e)
        return
      }

      this.$router.push({ path: '/share' })
    },
    handleNewText() {
      this.clearState()
      this.$router.push({ path: '/' })
    }
  }
}
</script>

<style scoped>
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 2rem;
  min-height: 80px;
}

.logo {
  background-image: url('~@/assets/images/logo.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 160px;
  height: 24px;
  margin-top: 5px;
  /* cursor: pointer; */
}
</style>
